import Lottie from "lottie-react";
import React from "react";
import campingAnimation from "../../assets/Home/camping.json";
import "./Home.css";

const Home = () => {
  return (
    <div id="home" className="home">
      <div className="mainTextContainer">
        <h1>ONRUST WILD CAMPING</h1>
        <p>FIND YOUR TRUE WILDERNESS SPIRIT WITH US!</p>
      </div>
    </div>
  );
};

export default Home;