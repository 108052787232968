import React from "react";
import './About.css'

export default function About() {
  return (
    <div id="about" className="aboutContainer">
      <img src={require('../../assets/tent.png')} width={200} height={200} style={{paddingTop: 70, paddingBottom: 70}}/>
      {/* You can replace this with an actual icon component or image */}
      <h1 className="aboutTitle">Find Your True Wilderness Spirit With Us!</h1>
      <p className="aboutDescription">
        Welcome to Onrust 4×4 Wild Camping where we invite you to indulge in an
        extraordinary 4×4 wild camping experience amid the captivating Cederberg
        Mountains. Our tailored excursions seamlessly blend off-road exploration
        with the tranquillity of wild camping, offering a unique opportunity to
        immerse yourself in the rugged beauty of this pristine wilderness. Join
        us at Onrust 4×4 Wild Camping, where every venture unveils a new facet
        of nature's greatness, and each night is spent beneath the vast African
        sky, creating memories that resonate with the spirit of adventure.
      </p>
    </div>
  );
}
