import React from "react";
import "./Gallery.css";

function Gallery() {
  return (
    <div id="gallery" className="gallery-container">
      <h2 style={{color: "#8b4b3e"}}>Our Gallery</h2>
      <div className="gallery">
        <img src={require("../../assets/1.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/2.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/3.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/4.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/5.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/6.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/7.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/8.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/9.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/10.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/11.jpg")} alt="Gallery image 1" />
        <img src={require("../../assets/12.jpg")} alt="Gallery image 1" />
      </div>
    </div>
  );
}

export default Gallery;
