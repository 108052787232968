import React from "react";
import './Contact.css'

export function Contact() {
  return (
    <div id="contact" className="contact">
      <a href="mailto:onrustbookings@gmail.com" className="contact-item">
        <i className="fas fa-envelope"></i>
        <span style={{paddingTop: 50, paddingBottom: 30, fontSize: 20, fontWeight: "bold"}}>onrustbookings@gmail.com</span>
      </a>
      <a
        href="https://www.instagram.com/onrustwildcamping/"
        className="contact-item"
        target="_blank"
      >
        <i className="fab fa-instagram"></i>
        <span style={{ paddingBottom: 30, fontSize: 20, fontWeight: "bold"}}>@onrustwildcamping</span>
      </a>
    </div>
  );
}

export default Contact;
