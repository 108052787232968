import Logo from "./assets/camping2.svg";
import Flower from "./assets/flower.svg";
import Pay from "./assets/pay2.svg";
import Pay3 from "./assets/pay3.svg";
import G1 from "./assets/1.JPG";
import G2 from "./assets/2.JPG";
import G3 from "./assets/main1.JPG";
import Flower1 from "./assets/flower1.JPG";
import Flower2 from "./assets/flower2.JPG";
import "./App.css";
import { useRef } from "react";
import Button from "./Components/Button";
import Card from "./Components/Card";
import Home from "./Screens/Home/Home";
import NavBar from "./Components/NavBar/NavBar";
import About from "./Screens/About/About";
import Reviews from "./Screens/Reviews/Reviews";
import BookingForm from "./Screens/BookingForm/BookingForm";
import Gallery from "./Screens/Gallery/Gallery";
import Contact from "./Screens/Contact/Contact";

function App() {
  const myRef = useRef<HTMLDivElement>(null);

  const executeScroll = () =>
    myRef.current &&
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const BookingProcedure = () => {
    return (
      <div className="bookingPro">
        <img src={Pay3} style={{ width: 100, height: 100 }} />
        <h2>Booking Procedure:</h2>

        <ul>
          <li>WhatsApp me the SA id's.</li>
          <li>Dates required.</li>
          <li>Payment via EFT.</li>
          <li>Once payment clears, the exact location pin is sent.</li>
          <li>Please keep all refuse and remove when you leave.</li>
          <li>WhatsApp me when you ride out.</li>
        </ul>
      </div>
    );
  };

  const BankingDetails = () => {
    return (
      <div className="bankingDetails">
        <h3>Banking details:</h3>
        <h4>Bank name - Standard Bank</h4>
        <h4>Account - Cheque Account</h4>
        <h4>Beneficiary name - SMITHSTONE (PTY) LTD</h4>
        <h4>Beneficiary account number - 10180887430</h4>
      </div>
    );
  };

  return (
    <div className="App">
      <NavBar />
      <Home />
      <About />
      <Gallery />
      {/* <Reviews /> */}
      <Contact />
      <BookingForm />

      {/* <div className="header">
        <div className='headerText'>
          <h1>
            Clanwilliam Onrust Overnight Camping & Trails
          </h1>

          <Button text='Find Out More' onClick={executeScroll} />
          
        </div>

        <div>
          <img src={Logo} alt='no image' />
        </div>
      </div>

      <div className='aboutSection' ref={myRef}>
        <h1>
          It's the West Coast <span style={{ color: '#DF3B57' }}>flower season</span>. Take your 4x4 overnight on <span style={{ color: '#DF3B57' }}>Farm Onrust</span> with a spectacular view all round.
        </h1>

        <img src={Flower} />
      </div>


      <div className='ourGallery'>
        <h1>
          Our Gallery
        </h1>

        <div className='images'>
          <img src={G1} />
          <img src={G2} />
          <img src={G3} />
          <img src={Flower1} />
          <img src={Flower2} />
        </div>

      </div>

      <div className='PandB'>
        <h1>Prices & Bookings:</h1>
        <h2>Wild Camping</h2>

        <div className='details'>
          <Card header={'Price per night:'} />

          <BookingProcedure />

          <BankingDetails />

          <h3>Contact me on <span style={{color: "#456990"}}>0832546168</span> with ID or passport.</h3>

        </div>


      </div> */}
    </div>
  );
}

export default App;
