import React, { useState } from "react";
import "./Navbar.css";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleScrollTo = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsOpen(false); // Close navbar when a link is clicked
  };

  return (
    <div className="navbar">
      <button className="toggle" onClick={toggleNavbar}>
        {isOpen ? "Close" : "Menu"}
      </button>
      <div className={`nav-items ${isOpen ? "open" : ""}`}>
      <ul>
          <li><button onClick={() => handleScrollTo("home")}>Home</button></li>
          <li><button onClick={() => handleScrollTo("about")}>About</button></li>
          <li><button onClick={() => handleScrollTo("gallery")}>Gallery</button></li>
          <li><button onClick={() => handleScrollTo("contact")}>Contact</button></li>
          <li><button onClick={() => handleScrollTo("booking")}>Make a Booking</button></li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;